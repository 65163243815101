.image-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
}

.image-gallery img {
  width: auto;
  height: auto;
  max-width: 100%;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.image-gallery img:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .image-gallery img {
    width: 100%;
    margin-bottom: 5px;
    /* 减少间距 */
  }
}