.section {
    padding: 40px;
    text-align: center;
  }
  
  .section h2 {
    margin-bottom: 20px;
  }
  
  .section p {
    font-size: 18px;
    color: #666;
  }
  