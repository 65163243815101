header {
    background-color: #3681aa;
    color: #fff;
    padding: 10px 0;
  }
  
  header .logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
  }
  
  nav ul li {
    margin: 0 15px;
  }
  
  nav ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }
  
