/* 基础样式 */
.about-section {
    padding: 80px 0;
    background-color: #f9f9f9;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  /* 标题和介绍 */
  .section-header {
    text-align: center;
    margin-bottom: 60px;
    background-color: #333; /* 更改背景色为深色 */
    color: #fff; /* 文字颜色 */
    padding: 20px; /* 添加内边距 */
    border-radius: 8px; /* 添加圆角 */
  }
  
  .section-header h1 {
    font-size: 36px;
    font-weight: 700;
    /*color: #333;
    margin-bottom: 20px;*/
  }
  
  .section-header p {
    font-size: 18px;
    color: #fff;
  }
  
  /*公司业务架构样式*/
  .business-struture h2{
    display: flex;
    justify-content: flex-start;
    font-size: 35px;
    color: #090909ec;
  }
  
  .business-struture{
    flex:0 0 auto;/*定义了项目的初始大小，默认为auto*/
  }

  .list-ul{
    display: flex;
    flex-wrap: wrap;/*允许项目换行*/
  }

  .ul-1,.ul-2,.ul-3,.ul-4{
    flex: 1;/*每个项目占据相同的宽度*/
    margin: 10px;/*添加外边距，以避免项目之间的重叠*/
  }

  .ul-1,.ul-2,.ul-3,.ul-4 p{
    font-size: 30px;
    color: #121111;
  }

  .mess-text{
    font-size: 25px;
  }
  
  .list-ul div{
    /*默认样式*/
    cursor: pointer;
    color: #000;
    background-color: #f0f0f0;
    padding: 10px;
    margin: 5px;
    border: radius 5px;
  }

  .list-ul div:hover{
    /*鼠标悬停*/
    background-color: #186893;
    border-color: #138a8e; /* 鼠标悬停时边框颜色变为深蓝色 */
    transition: background-color 0.3s ease; /* 背景颜色变化有过渡效果 */
    transform: scale(1.05); /* 鼠标悬停时轻微放大元素 */
    opacity: 0.9; /* 鼠标悬停时降低元素透明度 */
    cursor: pointer; /* 鼠标悬停时光标变为指针 */
  }

  .list-ul div.active{
    /*被点击时的样式*/
    background-color: #16819e;
    color: #fff;
  }

  /* 团队成员样式 */
  .company-logo{
    text-align: center;
    margin-bottom: 25px;
    
  }
  .company-logo p{
    font-size: 30px;
    color: #02070b;
  }
  .logo-image{
    max-width: 200px;
    height: auto;
  }

  .member-grid{
    display: flex;
    grid-template-columns:space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .team-member{
    text-align: center;
    margin: 10px;/*添加外边距*/
    flex: 1;/*允许成员平均分配空间*/
    min-width: 150px;/*设置最小宽度*/
    padding: 20px;/*添加内边距*/
    border: 1px solid #0f567f;/*添加边框*/
    border-radius: 5px;/*添加圆角*/
  }
  .team-member h3{
    margin: 10px 0;
    font-size: 1.2em;
  }
  .team-member p{
    margin: 5px 0;
    color: #121010;
  }
  /* 价值观样式 */
  .values {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .values h2 {
    margin-bottom: 20px;
    font-size: 28px;
    color: #333;
  }
  
  .values p {
    font-size: 20px;
    color: #090909;
  }
  
  /* 合作伙伴样式 */
  .partners {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .partners h2 {
    margin-bottom: 30px;
    font-size: 24px;
    color: #333333;
  }
  
  .partners p{
    color: red;
    font-size: 50px;
  }
  .partner-logos {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .partner-logos img {
    max-width: 150px;
    margin: 15px;
    opacity: 0.8;
    transition: opacity 0.3s ease;
  }
  
  .partner-logos img:hover {
    opacity: 1;
  }
  
  /*全局背景渐变*/
  /* 设置整个页面的背景渐变 */
/* About.css 文件 */
.about-section {
  background-image: linear-gradient(to right, #f9f9f7, #e5e5e9); /* 从#6dd5ed渐变到#2193b0 */
  /* 其他样式 */
}

